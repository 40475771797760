.App {
  text-align: center;
  font-family: 'Trebuchet MS';
  background-color: rgb(255, 255, 255);
}

.Dropdown{
  width: 50%;
  padding-left: 25%;
  padding-right: 25%;
  padding-top: 10%;
  padding-bottom: 5%;
}

.OfficerBox{
  border: 1px solid rgb(253, 242, 220, 0.2);
  margin-bottom: 5%;
  padding-top: 10px;
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 10%;
  background-color: rgba(253, 242, 220, 0.5);
}

.AllOfficers{
  padding-left: 7%;
  padding-right: 7%;
}

.Logo{
  width: 25%;
  position: absolute;
  right: -2%;
}